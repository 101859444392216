import cn from "classnames";
import capitalize from "lodash/capitalize";
import _get from "lodash/get";
import _has from "lodash/has";
import _map from "lodash/map";
import startCase from "lodash/startCase";
import upperCase from "lodash/upperCase";
import type { InputHTMLAttributes } from "react";
import React, { forwardRef, ReactNode, useContext } from "react";
import type { SelectInputProps } from "../components/Input/SelectInput";
import { SelectInput } from "../components/Input/SelectInput";
import LocalizationContext, { LocalizableString } from "./LocalizationContext";

export type TransformOptions =
  | "startCase"
  | "capitalize"
  | "upperCase"
  | "none";

const transformByKey = (transform: TransformOptions) => {
  switch (transform) {
    case "startCase":
      return startCase;
    case "capitalize":
      return capitalize;
    case "upperCase":
      return upperCase;
    case "none":
    default:
      return w => w;
  }
};

export interface LocalizeSelectProps extends SelectInputProps {
  placeholder: LocalizableString;
  placeholderTransform?: TransformOptions;
}

export const LocalizeSelect = ({
  placeholder,
  placeholderTransform = "none",
  ...rest
}: LocalizeSelectProps) => {
  const localizedStrings = useContext(LocalizationContext);

  const LocalizedPlaceholder = transformByKey(placeholderTransform)(
    _get(localizedStrings, placeholder, placeholder)
  );

  return <SelectInput {...rest} placeholder={LocalizedPlaceholder} />;
};

export interface LocalizeSelectAndOptionLabelsProps
  extends LocalizeSelectProps {
  labelsTransform?: TransformOptions;
}

export const LocalizeSelectAndOptionLabels = ({
  placeholder,
  placeholderTransform = "none",
  options,
  labelsTransform = "none",
  ...rest
}: LocalizeSelectAndOptionLabelsProps) => {
  const localizedStrings = useContext(LocalizationContext);

  const LocalizedPlaceholder = transformByKey(placeholderTransform)(
    _get(localizedStrings, placeholder, placeholder)
  );

  const localizedOptions = _map(options, ({ label, ...others }) => ({
    ...others,
    label: transformByKey(labelsTransform)(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore ignore for now. Would be nice to have a way to confirm that (now)label exists on the options. Couldn't think of an easy way
      _get(localizedStrings, label, label)
    ),
  }));

  return (
    <SelectInput
      {...rest}
      placeholder={LocalizedPlaceholder}
      options={localizedOptions}
    />
  );
};

export interface LocalizeInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: LocalizableString;
  placeholderTransform?: TransformOptions;
  className?: string;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/display-name
export const LocalizeInput = forwardRef<HTMLInputElement, LocalizeInputProps>(
  (
    {
      placeholder,
      placeholderTransform = "none",
      className,
      ...rest
    }: LocalizeInputProps,
    ref
  ) => {
    const localizedStrings = useContext(LocalizationContext);

    const LocalizedPlaceholder = transformByKey(placeholderTransform)(
      _get(localizedStrings, placeholder, placeholder)
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore not sure why ref={re} is throwing an error
    return (
      <input
        {...rest}
        className={cn("input input-bordered", className)}
        ref={ref}
        placeholder={LocalizedPlaceholder}
      />
    );
  }
);

interface LocalizeDataProps {
  children?: ReactNode;
  className?: string;
  price?: number;
  string?: string;
  tooltip?: string;
  tooltipTransform?: TransformOptions;
  transform?: TransformOptions;
}

const LocalizeData = ({
  children,
  className,
  price,
  string: localizedStringKey,
  tooltip,
  tooltipTransform = "none",
  transform = "none",
}: LocalizeDataProps) => {
  const localizedStrings = useContext(LocalizationContext);

  let content: ReactNode = "";

  if (!localizedStrings) {
    console.error("no localizations to pull from");
    content = "no localizedString. You may not have a context";
  } else if (localizedStringKey) {
    if (!_has(localizedStrings, localizedStringKey)) {
      // if key doesn't exist in current locale, send message off to sentry. don't crash, return key as worst case
      // const warnMessage = `No translation exists for "${localizedStringKey}" in locale:${_get(
      //   localizedStrings,
      //   "locale",
      //   "invalid locale"
      // )}`;
      // console.warn(warnMessage);
      // Sentry.captureMessage(warnMessage);
    }
    content = transformByKey(transform)(
      _get(localizedStrings, localizedStringKey, localizedStringKey)
    );
  } else if (price !== undefined) {
    content = localizedStrings.formatPrice(price);
  }

  return className || tooltip ? (
    <div
      className={cn(className, {
        "tooltip tooltip-position-top": !!tooltip,
      })}
      data-tooltip={
        tooltip
          ? transformByKey(tooltipTransform)(
              _get(localizedStrings, tooltip, tooltip)
            )
          : undefined
      }
    >
      {content}
      {children}
    </div>
  ) : (
    <>
      {content}
      {children}
    </>
  );
};

export interface LocalizeProps extends LocalizeDataProps {
  string?: LocalizableString;
  tooltip?: LocalizableString;
}

/**
 * Localize the given data. For data that cannot be guaranteed to have existing localizations, see `DynamicLocalize`
 * @param props
 */
export function Localize(props: LocalizeProps) {
  return <LocalizeData {...props} />;
}

export interface DynamicLocalizeProps extends LocalizeDataProps {
  string?: string;
  tooltip?: string;
}

/**
 * Localize the given data which may or may not have a localization. In most scenarios, one should prefer to use `Localize` instead.
 * @param props
 */
export function DynamicLocalize(props: DynamicLocalizeProps) {
  return <LocalizeData {...props} />;
}

export default Localize;
