import en_US from "../en_US";
import { LocalizedStrings } from "../localization.types";
import strings from "./strings.json";

export const es_MX: LocalizedStrings = {
  ...en_US,
  ...strings,
  locale: "es_MX",
  label: "Español",
  flag: "🇲🇽",
  currency: "MXN",
  formatPrice: (price) =>
    Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(price),
};

export default es_MX;
