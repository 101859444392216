import cn from "classnames";
import _find from "lodash/find";
import React, { ReactNode } from "react";

export interface SelectOption {
  key?: string;
  label?: string;
  render?: () => ReactNode;
  value: string;
}

export interface SelectInputBaseProps<OptionType> {
  options: ReadonlyArray<OptionType>;
  placeholder: string;
  required?: boolean;
  selected: string | undefined;
  className?: string;
  defaultValue?: string;
  name: string;
  onChange(newValue: string): any;
  deselectable?: boolean;
  deselectLabel?: string;
}

export type SelectInputProps = SelectInputBaseProps<SelectOption>;

export const SelectInput = ({
  className,
  defaultValue = "",
  name,
  onChange,
  options = [],
  placeholder,
  required = false,
  selected,
  deselectable = false,
  deselectLabel = "Unset",
}: SelectInputProps) => {
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (deselectable && newValue == "") {
      onChange("");
    }
    const selectedOption = _find(options, { value: newValue });
    if (selectedOption) {
      onChange(selectedOption.value);
    }
  };

  return (
    <select
      className={cn("select select-bordered", className)}
      id={name}
      name={name}
      onChange={handleChange}
      required={required}
      value={selected ?? defaultValue}
    >
      {placeholder ? (
        <option key="placeholder" value="" disabled>
          {placeholder}
        </option>
      ) : null}
      {deselectable && selected ? (
        <option key="deselectable" value="">
          {`${deselectLabel}`}
        </option>
      ) : null}
      {options.map(({ label, key, value, render }) => (
        <option key={value} value={value}>
          {label ?? key ?? value}
          {render ? render() : ""}
        </option>
      ))}
    </select>
  );
};

export default SelectInput;
