import React, { ReactNode } from "react";
import {
  IconRadioBoard,
  IconRadioBoardOption,
} from "../../components/Input/IconRadioBoard";
import { LocalizeSelect } from "../../localization/Localize";
import { SortOrder } from "../../utils/sort";

export interface SortOption {
  key?: string;
  label?: string;
  render?: () => ReactNode;
  value: string;
}

export interface SortDropdownProps {
  sortBy?: string;
  sortByOptions: SortOption[];
  sortOrder: SortOrder;
  onSortByChange(newSort: string | undefined): any;
  onSortOrderChange(newSortOrder: SortOrder): any;
}

const SortDropdownRadioOptions: IconRadioBoardOption<SortOrder>[] = [
  {
    value: SortOrder.Asc,
    iconClassName: "fal fa-arrow-from-bottom",
  },
  {
    value: SortOrder.Desc,
    iconClassName: "fal fa-arrow-from-top",
  },
];

export function SortDropdown({
  onSortByChange,
  onSortOrderChange,
  sortBy,
  sortByOptions,
  sortOrder,
}: SortDropdownProps) {
  console.log("sortByOptions: ", sortByOptions);
  return (
    <div className="flex">
      <LocalizeSelect
        required={false}
        name="sort-by"
        selected={sortBy}
        options={sortByOptions}
        placeholder="sort by"
        placeholderTransform="startCase"
        onChange={onSortByChange}
        className=""
      />
      <IconRadioBoard
        options={SortDropdownRadioOptions}
        selected={sortOrder}
        onChange={onSortOrderChange}
        disabled={!sortBy}
      />
    </div>
  );
}

export default SortDropdown;
