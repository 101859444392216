import cn from "classnames";
import _map from "lodash/map";
import React from "react";

export interface IconRadioBoardOption<ValueType> {
  iconClassName: string;
  value: ValueType;
}

export interface IconRadioBoardProps<ValueType> {
  className?: string;
  disabled?: boolean;
  onChange(newSelectOption: ValueType): void;
  options: ReadonlyArray<IconRadioBoardOption<ValueType>>;
  selected: ValueType | undefined;
}

export const IconRadioBoard = <ValueType extends any>({
  className,
  disabled = false,
  onChange,
  options = [],
  selected = undefined,
}: IconRadioBoardProps<ValueType>) => {
  return (
    <div className={cn("flex", className)}>
      {_map(options, ({ value, iconClassName }) => (
        <button
          key={value as string}
          className={cn("btn no-focus-ring", {
            "btn-text-primary": selected === value,
            "btn-ghost": selected !== value,
          })}
          disabled={disabled}
          onClick={() => {
            if (!disabled && selected !== value) {
              onChange(value);
            }
          }}
        >
          <i className={iconClassName}></i>
        </button>
      ))}
    </div>
  );
};

export default IconRadioBoard;
