import _reverse from "lodash/reverse";
import _sortBy from "lodash/sortBy";

export enum SortOrder {
  Asc = 1,
  Desc = -1,
}

export function sortAlphabeticallyByName<T extends { name: string }>(
  items: T[]
): T[] {
  return sortByProperty(items, "name", SortOrder.Desc);
}

type sortFunction<T> = (item: T) => boolean;

export function sortByProperty<T extends Record<string, unknown>>(
  items: T[],
  propertyOrSortFunction: string | sortFunction<T> | undefined,
  order: SortOrder = SortOrder.Desc
): T[] {
  if (!propertyOrSortFunction) {
    return items;
  }
  switch (order) {
    case SortOrder.Asc:
      return _reverse(_sortBy(items, propertyOrSortFunction));
    case SortOrder.Desc:
      return _sortBy(items, propertyOrSortFunction);
  }
}
