import _map from "lodash/map";
import React from "react";
import en_GB from "./en_GB";
import en_US from "./en_US";
import es_MX from "./es_MX";
import type { LocalizableString, LocalizedStrings } from "./localization.types";

export const LocalizedStringsByLocale: { [key: string]: LocalizedStrings } = {
  en_US: en_US,
  en_GB: en_GB,
  es_MX: es_MX,
};

export const LocaleBasicInfos = _map(
  LocalizedStringsByLocale,
  ({ label, flag, locale }) => ({ label, flag, locale })
);

export const LocalizationContext = React.createContext(
  LocalizedStringsByLocale["en_US"]
);

export type { LocalizableString, LocalizedStrings };

export default LocalizationContext;
