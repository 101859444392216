import en_US from "../en_US";
import { LocalizedStrings } from "../localization.types";

export const en_GB: LocalizedStrings = {
  ...en_US,
  locale: "en_GB",
  flag: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
  currency: "GBP",
  formatPrice: (price) =>
    Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(price),
};

export default en_GB;
