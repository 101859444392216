import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: "/graphql",
});

export { gql, useMutation, useQuery } from "@apollo/client";
export { StandardLoader } from "../components/StandardLoader";

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
export const GraphQLWrapper = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/display-name
export const withGraphQLWrapper = (Component) => (props) =>
  (
    <GraphQLWrapper>
      <Component {...props} />
    </GraphQLWrapper>
  );
