import strings from "../generated/strings.json";
import { LocalizedStrings } from "../localization.types";

console.log("strings", strings);

export const en_US: LocalizedStrings = {
  ...strings,
  locale: "en_US",
  label: "English",
  flag: "🇺🇸",
  currency: "USD",
  // for USD, price will come in as cents, need to convert to dollars
  formatPrice: (price) =>
    Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price / 100),
};

export default en_US;

// https://en.wikipedia.org/wiki/ISO_4217
